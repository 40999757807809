import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import PortableText from '@sanity/block-content-to-react';
import SEO from '../components/SEO';
import Breadcrumbs from '../components/Breadcrumbs';
import { PageGrid } from '../styles/PageStyles';
import RequestACallback from '../components/RequestACallback';
import { ServiceCard, ServiceGrid } from '../styles/ServiceGridStyles';

export default function ServicesPage({ data }) {
  const { page, services } = data;
  return (
    <>
      <SEO
        title={page.title}
        image={page.image?.asset?.fluid?.src}
        description="Call Emerald Heating now on 07538008378 to discuss a range of services available."
      />
      <Breadcrumbs currentPage={page} />
      <PageGrid>
        {page.image?.asset?.fluid && (
          <Img fluid={page.image?.asset?.fluid} alt={page.title} />
        )}

        <div>
          <h1>{page.title}</h1>
          {page._rawContent && <PortableText blocks={page._rawContent} />}
          <ServiceGrid>
            {services.nodes.map((service) => (
              <li key={service.id}>
                <ServiceCard>
                  <h3>{service.title}</h3>
                  <p>{service.description}</p>
                  <Link to={service.slug.current} className="button secondary">
                    View details
                  </Link>
                </ServiceCard>
              </li>
            ))}
          </ServiceGrid>
        </div>
        <RequestACallback />
      </PageGrid>
    </>
  );
}

export const query = graphql`
  query($slug: String!) {
    page: sanityPage(slug: { current: { eq: $slug } }) {
      title
      id
      slug {
        current
      }
      _rawContent(resolveReferences: { maxDepth: 10 })
      image {
        asset {
          fluid(maxWidth: 800) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    services: allSanityService {
      nodes {
        title
        id
        slug {
          current
        }
        description
        image {
          asset {
            fluid(maxWidth: 800) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;
